<template>
  <div>
    <page-header-layout>
      <div class='main-page-content'>
        <el-form :inline='true'>
          <el-form-item label='部门名称'>
            <el-input
                v-model='queryParams.deptName'
                clearable
                @change="handleQuery"
                placeholder='请输入部门名称'
                size='small'
                @keyup.enter.native='handleQuery'
            />
          </el-form-item>
          <el-form-item label='状态'>
            <el-select @change="handleQuery"
                       v-model='queryParams.status' clearable placeholder='部门状态' size='small'>
              <el-option
                  v-for='dict in statusOptions'
                  :key='dict.key'
                  :label='dict.value'
                  :value='dict.key'
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
                class='filter-item'
                icon='el-icon-search'
                size='mini'
                type='primary'
                @click='handleQuery'
            >搜索
            </el-button>
            <el-button
                class='filter-item'
                icon='el-icon-plus'
                size='mini'
                type='primary'
                v-has-authority="'dept_add'"
                @click='handleAdd'
            >新增
            </el-button>
          </el-form-item>
        </el-form>

        <el-table
            v-loading='loading'
            :data='deptList'
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            default-expand-all
            row-key='id'
        >
          <el-table-column label='部门名称' prop='dept_name' width='260'></el-table-column>
          <el-table-column label='排序' prop='order_num' width='200'></el-table-column>
          <el-table-column :formatter='statusFormat' label='状态' prop='status' width='100'></el-table-column>
          <el-table-column align='center' label='创建时间' prop='created_at' width='200'/>
          <el-table-column align='center' class-name='small-padding fixed-width' label='操作'>
            <template slot-scope='scope'>
              <el-button
                  icon='el-icon-edit'
                  size='mini'
                  type='text'
                  v-has-authority="'dept_edit'"
                  @click='handleUpdate(scope.row)'
              >修改
              </el-button>
              <el-button
                  icon='el-icon-plus'
                  size='mini'
                  type='text'
                  @click='handleAdd(scope.row)'
                  v-has-authority="'dept_add'"
              >新增
              </el-button>
              <el-button
                  v-if='scope.row.parentId != 0'
                  icon='el-icon-delete'
                  size='mini'
                  v-has-authority="'dept_del'"
                  type='text'
                  @click='handleDelete(scope.row)'
              >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--        <el-input type="textarea" placeholder="placeholder" rows='20' :value='JSON.stringify(deptList,null,4)'>{{JSON.stringify(deptList,null,4)}}</el-input>-->
      </div>

    </page-header-layout>
    <!-- 添加或修改部门对话框 -->
    <el-dialog :title='title' :visible.sync='open' append-to-body width='600px'>
      <el-form ref='form' :model='form' :rules='rules' label-width='80px'>
        <el-row>
          <el-col v-if='form.parent_id !== 0' :span='24'>
            <el-form-item label='上级部门' prop='parent_id'>
              <el-cascader :props="{ expandTrigger: 'hover' ,value: 'id', label: 'dept_name',checkStrictly: true }"
                           v-model="form.parent_id" :options="deptOptions" :show-all-levels="false"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label='部门名称' prop='dept_name'>
              <el-input v-model='form.dept_name' placeholder='请输入部门名称'/>
            </el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label='显示排序' prop='order_num'>
              <el-input-number v-model='form.order_num' :min='0' controls-position='right'/>
            </el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label='负责人' prop='leader'>
              <el-input v-model='form.leader' maxlength='20' placeholder='请输入负责人'/>
            </el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label='联系电话' prop='phone'>
              <el-input v-model='form.phone' maxlength='11' placeholder='请输入联系电话'/>
            </el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label='邮箱' prop='email'>
              <el-input v-model='form.email' maxlength='50' placeholder='请输入邮箱'/>
            </el-form-item>
          </el-col>
          <el-col :span='12'>
            <el-form-item label='部门状态'>
              <el-radio-group v-model='form.status'>
                <el-radio
                    v-for='dict in statusOptions'
                    :key='dict.key'
                    :label='dict.key'
                >{{ dict.value }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot='footer' class='dialog-footer'>
        <el-button type='primary' @click='submitForm'>确 定</el-button>
        <el-button @click='cancel'>取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import { mapGetters } from 'vuex'

export default {
  name: 'SysDeptList',
  components: { PageHeaderLayout },
  computed: {
    ...mapGetters(['userPermissions', 'userDptInfo'])
  },
  data() {
    return {
      // 遮罩层
      loading: false,
      // 表格树数据
      deptList: [],
      // 部门树选项
      deptOptions: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 状态数据字典
      statusOptions: [{ key: 'Y', value: '正常' }, { key: 'N', value: '停用' }],
      // 查询参数
      queryParams: {
        deptName: undefined,
        status: undefined
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        parent_id: [
          { required: true, message: '上级部门不能为空', trigger: 'blur' }
        ],
        dept_name: [
          { required: true, message: '部门名称不能为空', trigger: 'blur' }
        ],
        order_num: [
          { required: true, message: '菜单顺序不能为空', trigger: 'blur' }
        ],
        email: [
          {
            required: false,
            type: 'email',
            message: '\'请输入正确的邮箱地址',
            trigger: ['blur', 'change']

          }
        ],
        phone: [
          {
            required: false,
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    /** 查询部门列表 */
    async getList() {
      this.loading = true
      let queryParams = {}
      this.queryParams?.status ? queryParams['status'] = this.queryParams.status : ''
      this.queryParams?.deptName ? queryParams['deptName'] = this.queryParams.deptName : ''
      let { list } = await this.$api.sysDeptList(queryParams)
      this.deptList = await this.handleTree(list, 'id', 'parent_id')
      console.log(this.deptList)

      this.loading = false
    },
    // 字典状态字典翻译
    statusFormat(row, column) {
      let find = this.statusOptions.find((v) => {
            return ('' + v.key) == ('' + row[column.property])
          }
      )
      if (find) {
        return find.value
      } else {
        return ''
      }

    },
    /** 转换部门数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children
      }
      return {
        id: node.dept_id,
        label: node.dept_name,
        children: node.children
      }
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        parent_id: undefined,
        dept_name: undefined,
        order_num: 0,
        leader: undefined,
        phone: undefined,
        email: undefined,
        status: 'Y'
      }
      this.resetForm('form')
    },
    /** 搜索按钮操作 */
    handleQuery() {
      // dept_name
      this.getList()
    },
    /** 新增按钮操作 */
    handleAdd(row) {

      this.reset()
      if (row != undefined) {
        this.form.parent_id = row.id
      }
      this.getSysDept()
      this.title = '添加部门'
      this.open = true
    },
    async getSysDept() {
      let { list } = await this.$api.sysDeptList()
      this.deptOptions = this.handleTree(list, 'id', 'parent_id')
    },
    /** 修改按钮操作 */
    async handleUpdate(row) {
      this.reset()
      let { info } = await this.$api.getSysDept({ id: row.id })

      this.form = info
      this.getSysDept()
      this.open = true
    },

    /** 提交按钮 */
    submitForm: function() {
      if (Array.isArray(this.form['parent_id'])) {
        this.form['parent_id'] = this.form['parent_id'][this.form['parent_id'].length - 1]

      }


      this.$refs['form'].validate(async valid => {
        if (valid) {
          if (this.form.deptId != undefined) {
            this.$api.saveSysDept(this.form).then(response => {
              if (response.code === 0) {
                this.msgSuccess('修改成功')
                this.open = false
                this.getList()
              } else {
                this.msgError(response.msg)
              }
            })
          } else {
            let id = await this.$api.saveSysDept(this.form)
            if (id) {
              this.$notify.success('新增成功')
              this.open = false
              this.getList()
            }
          }
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm('是否确认删除名称为"' + row.dept_name + '"的数据项?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        if (row?.children&&row?.children?.length != 0) {
          this.$notify.error('该部门下有子部门，请先删除子部门！')

        } else {
          let id = await this.$api.deleteSysDept(row.id)
          if (id) {
            this.msgSuccess('删除成功')
            await this.getList()
          }
        }

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        })
      })

    }

  }
}
</script>

<style scoped>

</style>
